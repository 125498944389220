import React from 'react'

import { Container, Content } from './styles'
import { 
  FaTimes, 
  FaHome, 
  FaEnvelope, 
  FaRegSun, 
  FaUserAlt,  
  FaRegFileAlt,

} from 'react-icons/fa'
import { useNavigate } from 'react-router-dom';
import SidebarItem from '../SidebarItem'

const Sidebar = ({ active }) => {

  const closeSidebar = () => {
    active(false)
  }

  return (
    <Container sidebar={active}>
      <FaTimes onClick={closeSidebar} />  
      <Content>
        <SidebarItem Icon={FaHome} Text="Aguardando envio" to="/Home" />
        {/* <SidebarItem Icon={FaEnvelope} Text="E-mail" to="/Email" /> */}
        <SidebarItem Icon={FaRegFileAlt} Text="Enviados" to="/Enviados" />
        <SidebarItem Icon={FaUserAlt} Text="Cliente Cadastro" to="/Cadastro" />
        <SidebarItem Icon={FaRegSun} Text="Configurações" to="/Configuracoes" />
      </Content>
    </Container>
  )
}

export default Sidebar