import React, { useState, useEffect } from "react";
import './styles.css';
import api from "../../Services/api";
import { Container } from '../Home/styles';
import { FaBars } from 'react-icons/fa';
import Sidebar from '../Sidebar';

export const Configuracao = () =>{

    const showSidebar                        = () =>{ setSidebar(!sidebar)};
    const[idConfiguracao, setIdConfiguracao] = useState(0);
    const[host,setHost]                      = useState("");
    const[porta, setPorta]                   = useState("");
    const[secure, setSecure]                 = useState(false);
    const [sidebar, setSidebar]              = useState(false);
    const[user, setUser]                     = useState("");
    const[pass, setPass]                     = useState("");
    const[remetente, setRemetente]           = useState('');
    const[destinatario, setDestinatario]     = useState("");
    const[assunto, setAssunto]               = useState("");
    const[nomeempresa, setNomeEmpresa]       = useState("");
    const[copia, setCopia]                   = useState("");

    useEffect(() => {
        BuscaConfiguracao();
      },[]);

    let webtoken = localStorage.getItem("token");

    // enviar email
    const SendAltCad = (e) =>{
        e.preventDefault();
        CadastroConfig();
    }
    
    async function CadastroConfig(){


        if(host !== "" && porta !== "" && user !== "" && pass !== "" && nomeempresa !== "" && destinatario !== "" && assunto !== "") {

            api.defaults.headers.post   = {'Content-Type' : 'application/json'};
            api.defaults.headers.common = { 'x-access-token': webtoken };
            let cadastro;

            if(idConfiguracao === 0 ){
                cadastro = await NewConfig();
            }
            else{
                cadastro = await EnviaConfig();
            }
                

            if(cadastro.status === 200){
                alert('As Configuracoes foram salvas com sucesso!');

            }else{
            alert('Falha ao salvar Configuracoes. tente novamente');
            
        }

        }
        else{
            alert('Antenção! Preencha todos os campos')
        }

    }
    
    async function NewConfig(){   
        
        let response = await api.post(
            '/configuracao/cadastro/', {
                'host': host,
                'porta': porta,
                'secure': false,
                'user': user,
                'pass': pass,
                'remetente': remetente,
                'copia': copia,
                'assunto': assunto,
                'destinatario': destinatario,
                'nomeempresa' : nomeempresa
            }
            
            );
        return response;
    }

    async function EnviaConfig(){   
        
        let response = await api.put(
            '/configuracao/update/', {
                'idConfiguracao': idConfiguracao,
                'host': host,
                'porta': porta,
                'secure': secure,
                'user': user,
                'pass': pass,
                'remetente': remetente,
                'copia': copia,
                'assunto': assunto,
                'destinatario': destinatario,
                'nomeempresa' : nomeempresa
            }
            
            );
        return response;
    }

    async function gravaLocalstorage(){
       
            localStorage.removeItem("idConfiguracao");
            localStorage.removeItem("host");
            localStorage.removeItem("user");
            localStorage.removeItem("port");
            localStorage.removeItem("secure");  
            localStorage.removeItem("pass");
            localStorage.removeItem("remetente");
            localStorage.removeItem("destinatario");
            localStorage.removeItem("assunto");
            localStorage.removeItem("nomeempresa");
            localStorage.removeItem("copia");
    
    }

    async function BuscaConfiguracao(){

        api.defaults.headers.post   = {'Content-Type' : 'application/json'};
        api.defaults.headers.common = { 'x-access-token': webtoken };
        let CONFIGURACAO = await buscaConfig();

        if(CONFIGURACAO.status === 200){
            await gravaLocalstorage();
            if(CONFIGURACAO.data[0].id >= 0){
                setIdConfiguracao(CONFIGURACAO.data[0].id);
                setHost(CONFIGURACAO.data[0].host);
                setUser(CONFIGURACAO.data[0].user);
                setPorta(CONFIGURACAO.data[0].port);
                setSecure(CONFIGURACAO.data[0].secure);
                setPass(CONFIGURACAO.data[0].pass);
                setRemetente(CONFIGURACAO.data[0].remetente);
                setDestinatario(CONFIGURACAO.data[0].destinatario);
                setAssunto(CONFIGURACAO.data[0].assunto);
                setNomeEmpresa(CONFIGURACAO.data[0].nomeEmpresa);
                setCopia(CONFIGURACAO.data[0].copia);

                localStorage.setItem("idConfiguracao",CONFIGURACAO.data[0].id);
                localStorage.setItem("host",CONFIGURACAO.data[0].host);
                localStorage.setItem("user",CONFIGURACAO.data[0].user);
                localStorage.setItem("port",CONFIGURACAO.data[0].port);
                localStorage.setItem("secure",CONFIGURACAO.data[0].secure);  
                localStorage.setItem("pass",CONFIGURACAO.data[0].pass);
                localStorage.setItem("remetente",CONFIGURACAO.data[0].remetente);
                localStorage.setItem("destinatario",CONFIGURACAO.data[0].destinatario);
                localStorage.setItem("assunto",CONFIGURACAO.data[0].assunto);
                localStorage.setItem("nomeempresa",CONFIGURACAO.data[0].nomeEmpresa);
                localStorage.setItem("copia",CONFIGURACAO.data[0].copia);
            }
        }else{
        alert('Falha ao Buscar Configuracoes. tente novamente');

        }
    }

    async function buscaConfig(){
        let response = await api.get('/configuracao/');

        return response;

    }

    return(
        <>
            <div>
                <Container>
                    <FaBars onClick={showSidebar} />
                    {sidebar && <Sidebar active={setSidebar} />}
                </Container>
            </div>
    
            <div>
           
                <main>
                    <fieldset>
                        <legend>
                            <h2> Configurações </h2>
                        </legend>
                        <form onSubmit={SendAltCad}>
                            <label>Host:</label>
                            <input type="text" name="host" placeholder="Provedor SMTP" 
                                onChange={(e) =>{setHost(e.target.value)}} value={host}
                                maxLength={50}
                            />
                            <br/>
                            <label>Porta:</label>
                            <input type="number" name="porta" 
                                onChange={(e) =>{setPorta(e.target.value);}} value={porta}
                            />
                            <br/>
                            <label>Email:</label>
                            <input type="email" name="user" placeholder="Endereço de e-mail que enviara as informações " 
                                onChange={(e) =>{setUser(e.target.value);}} value={user}
                            />
                            <br/>
                            <label>Senha:</label>
                            <input type="password" name="password" placeholder="Senha do e-mail" 
                                onChange={(e) =>{setPass(e.target.value)}} value={pass}
                            />
                            <br/>
                            <label>Nome da Empresa:</label>
                            <input type="text" name="nomeEmpresa" placeholder="Nome da empresa que esta enviando o e-mail" 
                                onChange={(e) =>{setNomeEmpresa(e.target.value);}} value={nomeempresa}
                            />
                            <br/>
                            <label>Assunto:</label>
                            <input type="text" name="assunto" placeholder="Assunto do E-mail" 
                                onChange={(e) =>{setAssunto(e.target.value);}} value={assunto}
                            />
                            <br/>
                            <label>Destinatario:</label>
                            <input type="text" name="destinatario" placeholder="Endereço de e-mail do Destinatario" 
                                onChange={(e) =>{setDestinatario(e.target.value);}} value={destinatario}
                            />
                            <br/>
                            <label>Cópia para:</label>
                            <input type="text" name="cc" placeholder="Endereço de e-mail para enviar a cópia" 
                                onChange={(e) =>{setCopia(e.target.value);}} value={copia}
                            />
                            <label>

                            </label> 
                            <br/>
                            <input type="submit" value="SALVAR" />
                        </form>
                    </fieldset>

                </main>
            </div>    
        </>
    )
}