import React from "react";
import { Route, Routes, BrowserRouter } from 'react-router-dom';

import { Login } from "./Pages/Login";
import { Email } from "./Pages/Email";
import { Cadastro } from "./Pages/Cadastro";
import { Home } from "./Pages/Home";
import { AlterarCadastro } from "./Pages/AlterarCad";
import { Enviados } from "./Pages/Enviados";
import { Configuracao } from "./Pages/Configuracao";

const Rotas = () => {
    return(
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/Login" element={<Login />} />
            <Route path="/Home" element={<Home />} />
            <Route path="/Email" element={<Email />} />
            <Route path="/Cadastro" element={<Cadastro />} />
            <Route path="/AlterarCadastro" element={<AlterarCadastro />} />
            <Route path="/Enviados" element={<Enviados />} />
            <Route path="/Configuracoes" element={<Configuracao />} />
          </Routes>
       </BrowserRouter>
    )
}

export default Rotas;