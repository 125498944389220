import styled from 'styled-components';
import { Link } from "react-router-dom"

// #1A202C
export const Container = styled(Link)`
  display: flex;
  align-items: center;
  background-color: #2D2913; 
  font-size: 18px;
  color: white;
  padding: 10px;
  cursor: pointer;
  border-radius: 10px;
  text-decoration: none;
  margin: 0 15px 5px;
  > svg {
    margin: 0 20px;
  }
  &:hover {
    background-color: black;
  }
`;