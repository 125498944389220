import React, { useState } from "react";
import './altecad.css';
import api from "../../Services/api";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router";
import  FormGroup  from "@mui/material/FormGroup";
import { Container } from '../Home/styles';
import { FaBars } from 'react-icons/fa';
import Sidebar from '../Sidebar';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import InputMask from 'react-input-mask';
import Moment from 'moment';
///
export const AlterarCadastro = (route) =>{

    const showSidebar               = () =>{ setSidebar(!sidebar)};
    const [sidebar, setSidebar]     = useState(false);
    const { state } = useLocation();
    const navigate = useNavigate();
    const[idPessoa] = useState(state.id)
    const[nome, setNome] = useState(state.nome);
    const[datanascimento, setDatanascimento] = useState(Moment(state.dataNascimento).format('DD/MM/YYYY'));
    const[cpf, setCpf]       = useState(state.cpf);
    const[telefone, setTelefone] = useState(state.telefone);
    const[email, setEmail]       = useState(state.email);
    const[altDados, setAltDados] = useState( state.situacao === "0" ? false : true);

    // enviar email
    const SendAltCad = (e) =>{
        e.preventDefault();
        updateCadastro();
    }

    const LimparCampos = () =>{
        setNome('');
        setDatanascimento('');
        setCpf(''); 
        setTelefone('');
        setEmail('');
    }

    
    async function updateCadastro(){


        if(nome.length > 0 && datanascimento !== "" && cpf !== "" && email.length > 0 && telefone.length > 0) {

            api.defaults.headers.post   = {'Content-Type' : 'application/json'};;

            let cadastro = await EnviaCadastro();

            if(cadastro.status === 200){

                alert('Cadastro Alterado com sucesso!');
                LimparCampos();
                navigate('/Enviados');

            }else{

            alert('Falha ao alterar cadastro!');
            
        }

        }
        else{

            alert('Antenção! Preencha todos os campos')
        }

        

    }

    async function EnviaCadastro(){ 
        let response = await api.put(
            '/pessoa/update/', {
                'idPessoa': idPessoa,
                'nome': nome,
                'datanascimento': datanascimento.split('/')[2]+'-'+datanascimento.split('/')[1]+'-'+datanascimento.split('/')[0],
                'cpf': cpf,
                'telefone': telefone,
                'email': email,
                'situacao': altDados ? "1" : "0",
                'anexo': '',
            }
            
            );
        return response;
    }

    const Selecionado = (event) => {
        setAltDados(event.target.checked);
    };

    return(
        <>
            <div>
                <Container>
                    <FaBars onClick={showSidebar} />
                    {sidebar && <Sidebar active={setSidebar} />}
                </Container>
            </div>

            <main>
                <fieldset>
                    <legend>
                        <h2> Preencha os dados para efetuar o cadastro. </h2>
                    </legend>
                    <form onSubmit={SendAltCad}>
                        <label>Nome:</label>
                        <input type="text" name="nome" placeholder="Nome" 
                            onChange={(e) =>{setNome(e.target.value)}} value={nome}
                            maxLength={50}
                        />
                        {/* <br/> */}
                        {/* <input type="number" name="idade" placeholder="Idade" 
                            onChange={(e) =>{setIdade(e.target.value);}} value={idade}
                        /> */}
                        <br/>
                        <label>Data Nascimento:</label>
                        <InputMask mask="99/99/9999" name="datanascimento" 
                            onChange={(e) =>{setDatanascimento(e.target.value);}} value={datanascimento}
                        />
                        <br/>
                        <label>Cpf (Somente Números):</label>
                        <InputMask mask="999.999.999-99"
                            value={cpf}
                            onChange={(e) =>{setCpf(e.target.value.replace(/\D/g, ''));}}
                        
                        />
                        <br/>
                        <label>Telefone:</label>
                        <InputMask mask="(99) 9 9999-9999" name="telefone" placeholder="(DDD) 0 0000-0000" 
                            onChange={(e) =>{setTelefone(e.target.value.replace(/\D/g, ''))}} value={telefone}
                        />
                        <br/>
                        <label>E-mail:</label>
                        <input type="email" name="destinatarioemail" placeholder="E-mail " 
                            onChange={(e) =>{setEmail(e.target.value);}} value={email}
                        />
                        <br/>
                        <label>Status:</label>
                        <FormGroup>
                            <FormControlLabel  
                                control={
                                    <Checkbox 
                                     checked={altDados}
                                     onChange={Selecionado}
                                     inputProps={{'aria-label': 'controlled'}}
                                     sx={{'& .MuiSvgIcon-root': { fontSize: 28 }}} 
                                    
                                    />} 
                                    label="Email enviado" />
                        </FormGroup>
                        
                 
                        <label>

                        </label>
                        <br/>
                        <input type="submit" value="ALTERAR" />
                    </form>
                </fieldset>
            </main>
        </>
    )
}