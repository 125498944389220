import './App.css';
import Routes from './Routes';

function App() {
  return (   
    <Routes />
  );
}

export default App;
