import React, { useState } from "react";
import './email.css';
import api from "../../Services/api";

export const Email = () =>{

    const[nome, setNome] = useState('');
    const[datanascimento, setDatanascimento] = useState(0);
    const[cpf, setCpf]       = useState();
    const[telefone, setTelefone] = useState('');
    const[email, setEmail]       = useState('');

    const[host,setHost] = useState();
    const[porta,setPorta] = useState();
    const[ssl,setSsl]  = useState();
    const[usuario,setUsuario] = useState();
    const[senha, setSenha] = useState();

    const[campos, setCampos] = useState({
        'nome': '',
        'datanascimento': '',
        'cpf': '',
        'telefone': '',
        'email': '',
        'situacao': 0,
        'anexo': '',
    })
 
// enviar email
    const SendEmail = (e) =>{
        e.preventDefault();
        setCampos({
            'nome': nome,
            'datanascimento': datanascimento,
            'cpf': cpf,
            'telefone': telefone,
            'email': email,
            'situacao': 0,
            'anexo': '',
        });
        //console.log(' Aqui esta os campos : ',  campos);
        //ValidaEmail();
        ValidaCadastro();
        LimparCampos()
    }

    const LimparCampos = () =>{
        setNome('');
        setDatanascimento('');
        setCpf(''); 
        setTelefone('');
        setEmail('');
    }

    async function ValidaEmail(){

        api.defaults.headers.post   = {'Content-Type' : 'application/json'};
        api.defaults.headers.post   = {"Content-Type": 'multipart/form-data'};
        let email = await EnviaEmail();

        if(email.status === 200){
            alert('Email enviado com sucesso!')
 

        }else{
        alert('Falha ao enviar email')
        }

    }

    async function EnviaEmail(){   
        // const formData = new FormData();
        // Object.keys(campos).forEach(key => formData.append(key, campos[key]));
        let response = await api.post(
            '/send', campos
            
            );
          return response;
      }
    
//////////////
        async function ValidaCadastro(){

            //console.log('Campos ','Nome :' + nome + 'Dt Nasc :' + datanascimento + 'Cpf :' + cpf + 'E-mail :' + email + 'Telefone :' + telefone);

            if(nome.length > 0 && datanascimento != "" && cpf != "" && email.length > 0 && telefone.length > 0) {

                api.defaults.headers.post   = {'Content-Type' : 'application/json'};
                api.defaults.headers.post   = {"Content-Type": 'multipart/form-data'};
                let cadastro = await EnviaCadastro();

                if(cadastro.status === 200){
                    alert('Cadastro efetuado com sucesso!');




                }else{
                alert('Falha ao Cadastrar')
            }

            }
            else{
                //console.log('Antenção! Preencha todos os campos');
                alert('Antenção! Preencha todos os campos')
            }

            

        }

        async function EnviaCadastro(){   
            //console.log('inicio');  
            // const formData = new FormData();
            // Object.keys(campos).forEach(key => formData.append(key, campos[key]));
            let response = await api.post(
                '/cadastro', campos
                
                );
            return response;
        }


    return(
        <>
            <header>
                <h1> Cadastro.</h1>
            </header>
            <main>
                <fieldset>
                    <legend>
                        <h2> Preencha os dados para efetuar o cadastro. </h2>
                    </legend>
                    <form onSubmit={SendEmail}>
                        <label>Nome:</label>
                        <input type="text" name="nome" placeholder="Nome" 
                            onChange={(e) =>{setNome(e.target.value)}} value={nome}
                        />
                        {/* <br/> */}
                        {/* <input type="number" name="idade" placeholder="Idade" 
                            onChange={(e) =>{setIdade(e.target.value);}} value={idade}
                        /> */}
                        <br/>
                        <label>Data Nascimento:</label>
                        <input type="date" name="datanascimento"  
                            onChange={(e) =>{setDatanascimento(e.target.value);}} value={datanascimento}
                        />
                        <br/>
                        <label>Cpf (Somente Números):</label>
                        <input type="number" name="cpf" placeholder="000.000.000-00" 
                            onChange={(e) =>{setCpf(e.target.value);}} value={cpf}
                        />
                        <br/>
                        <label>Telefone:</label>
                        <input type="number" name="telefone" placeholder="(DDD) 0 0000-0000" 
                            onChange={(e) =>{setTelefone(e.target.value)}} value={telefone}
                        />
                        <br/>
                        <label>E-mail:</label>
                        <input type="email" name="destinatarioemail" placeholder="E-mail " 
                            onChange={(e) =>{setEmail(e.target.value);}} value={email}
                        />
                        
                        {/* <br/>
                        <input type="textarea" name="mensagem" placeholder="Digite sua mensagem" 
                            onChange={(e) =>{setDestinatarioEmail(e.target.value);}} value={destinatarioemail}
                        /> */}
                        <label>

                        </label>
                        <br/>
                        <input type="submit" value="CADASTRAR" />
                    </form>
                </fieldset>

            </main>
    
        </>
    )
}