import styled from 'styled-components';

export const bodyPage = styled.div`
    background-color: #ffffff;

`;

export const Container = styled.div`
    z-index: 9999;
    height: 70px;
    display: flex;
    background-color: #1A202C;
    box-shadow: 0 0 20px 3px;

    > svg {
        
        color: white;
        width: 20px;
        height: 20px;
        margin-top: 25px;
        margin-left: 32px;
        cursor: pointer;

    }

`;

export const Formulario = styled.form`
    display: flex;
    background-color: #1A202C;
    box-shadow: 0 0 20px 3px;
    padding-left: 20%;
    padding-right: 20%;
    padding-top: 5%;


`;

export const BtnEnviar = styled.div`
    margin-top: 40px;
    height: 40px;
    display: flex;
    background-color: #1A202C;
    align-items: center;
    justify-content: center;
    margin-left: 5%;
    margin-right: 5%;



`;

export const Titulo = styled.div`
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    
`;

export const negrito = styled.text`
    color: #fff;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    font-weight: bold;
`;

export const TextTitulo = styled.text`
    color: #fff;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    font-weight: bold;

`;

export const ContainerList = styled.div`
z-index:0;

`;

export const ListPessoa = styled.div`
    display:flex;
    padding: 5px;
    background-color: #c0c0c0;
    margin: 5px;
    border-color: blue;
    border-radius: 5px;
    justify-content: center;
    width: 100%;

`;

export const ItemListPessoa = styled.div`
    display: flex;
    background-color: #ffffff;
    padding: 20px;
    width: 100%;
    align-items: center;
    justify-content:center;

`;

export const ItemList = styled.div`
    background-color: #ffffff;

`;
  