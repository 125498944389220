import React, { useState } from "react";
import './cadastro.css';
import api from "../../Services/api";
import InputMask from 'react-input-mask';
import logoPorto from '../../Imagens/porto-seguro-logo.png'
export const Cadastro = () =>{

    const[nome, setNome] = useState("");
    const[datanascimento, setDatanascimento] = useState("");
    const[cpf, setCpf]       = useState("");
    const[telefone, setTelefone] = useState("");
    const[email, setEmail]       = useState("");
    const[disabled, setDisabled]   = useState(false);
    
    // enviar email
    const SendEmail = (e) =>{
        e.preventDefault();
        setDisabled(true);
        //console.log(' Aqui esta os campos : ',  campos);
        //ValidaEmail();
        ValidaCadastro();
    }

    const LimparCampos = () =>{
        setNome('');
        setDatanascimento('');
        setCpf(''); 
        setTelefone('');
        setEmail('');
    }

    async function ValidaCadastro(){

        if(nome.length > 0 && datanascimento !== "" && cpf !== "" && email.length > 0 && telefone.length > 0) {
            
            api.defaults.headers.post   = {'Content-Type' : 'application/json'};
            api.defaults.headers.post   = {"Content-Type": 'multipart/form-data'};
            let cadastro = await EnviaCadastro();

            if(cadastro.status === 200){
                alert('Cadastro efetuado com sucesso!');
                LimparCampos();
                setDisabled(false);

            }
            else{
                alert('Falha ao Cadastrar')
            }

        }else{
            alert('Antenção! Preencha todos os campos')
            setDisabled(false);
        }

        

    }

    async function EnviaCadastro(){   
        let response = await api.post(
            'pessoa/cadastro', {
                'nome': nome,
                'datanascimento': datanascimento.split('/')[2]+'-'+datanascimento.split('/')[1]+'-'+datanascimento.split('/')[0],
                'cpf': cpf,
                'telefone': telefone,
                'email': email,
                'situacao': 0,
                'anexo': '',
            }
            
            );
        return response;
    }

    return(
        <>
            <main className="fundo">
        
                <div className="logo">
                    <img src="https://cachoeiradacapivara.com.br/wp-content/uploads/2021/06/logo-cachoeira-capivara-resp.png" alt="" />
                </div>

                <div className="logo">
                    <img src={logoPorto} alt="" />
                </div>


                <form 
                    className="form" 
                    onSubmit={SendEmail}
                    >
                    
                    <input 
                        type="text" 
                        name="nome" 
                        className="input"
                        placeholder="Nome / Name" 
                        onChange={(e) =>{setNome(e.target.value)}} 
                        value={nome}
                        maxLength={100}
                    />
                    <InputMask 
                        mask="99/99/9999" 
                        className="input"
                        placeholder="Data Nascimento / Birth Date Day/Mouth/Year" 
                        type="tel"
                        name="datanascimento" 
                        onChange={(e) =>{setDatanascimento(e.target.value);}} 
                        value={datanascimento}
                    />
                    
                    <InputMask 
                        className="input"
                        placeholder="CPF / PASSPORT"
                        type="text" 
                        value={cpf}
                        onChange={(e) =>{setCpf(e.target.value);}}
                    
                    />

                    <InputMask 
                        className="input"
                        type="tel"
                        name="telefone" 
                        placeholder="Celular / Phone Number" 
                        onChange={(e) =>{
                            setTelefone(e.target.value.replace(/\D/g, ''))
                        }} 
                        value={telefone}
                    />

                    <input 
                        type="email" 
                        className="input"
                        name="destinatarioemail" 
                        placeholder="Email" 
                        onChange={(e) =>{setEmail(e.target.value);}} value={email}
                    />

                    <input disabled={disabled} type="submit" className="inputSubmit" value="CADASTRAR" />
                </form>
            </main>
       
        </>
    )
}