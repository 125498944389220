import React, { useState, useEffect } from "react";
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import { FaBars } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import api from "../../Services/api";

import { Container, Titulo, ListPessoa, ItemListPessoa,ContainerList } from './styled';

import Sidebar from '../Sidebar';


//  1 | admin | admin@sistema.com | admin123


export const Enviados = () =>{

  const navigate                  = useNavigate();
  const [sidebar, setSidebar]     = useState(false);
  const showSidebar               = () =>{ setSidebar(!sidebar)};
  const [listemail, setlistemail] = useState(['']);
  const [situacao]   = useState(1);

  useEffect(() => {
    ValidaUsuario();
  });

 /////////////////////// BUSCA PESSOA //////////////////////
  async function ValidaUsuario(){
      

      let webtoken = await localStorage.getItem("token");
      api.defaults.headers.post   = {'Content-Type' : 'application/json'};
      api.defaults.headers.common = { 'x-access-token': webtoken };

      let usuario = await buscaUsuario();
      if(usuario.data[0] != null){
        setlistemail(usuario.data);

      }

  }
  async function buscaUsuario(){
      let response = await api.get('/pessoa/'+ situacao);
      return response;
  }

  /////////////////////// FIM BUSCA PESSOA //////////////////////


  const editar = (value) =>{
    navigate('/AlterarCadastro', { state : value });
  }




    return(
<div>
      <div>

            <Container>
              <FaBars onClick={showSidebar} />
              {sidebar && <Sidebar active={setSidebar} />}
            </Container>
      </div>


      <div>
         <Titulo> 
            <label>E-mails Enviados </label></Titulo>
         

          <ContainerList>
            
              {listemail[0]?
                    <>
                    {listemail.map((value) => {
                      const labelId = `checkbox-list-label-${value}`;

                      return (
                            <ListItem
                              key={value.id+value.cpf}
                              secondaryAction={
                                <>
                                  <IconButton edge="end" aria-label="editar" className="btHome" onClick={() => editar(value)} >
                                    <EditIcon/>
                                  </IconButton>
                                </>
                              }
                              disablePadding
                            >
                           
                              <ListPessoa>
                              <ItemListPessoa>
                                <ListItemButton role={undefined}  dense>
                                  <ListItemText id={labelId} primary={`Nome: ${value.nome} - Email: ${value.email} - Telefone: ${value.telefone} - Documento: ${value.cpf}`} />
                                </ListItemButton>
                                </ItemListPessoa>
                              </ListPessoa>
                              
                            </ListItem>
                            
                      );
                    })}
                </>

              :
                
              <Titulo>
                <label>Nenhum Cadastro novo encontrado !</label>
              </Titulo>

              }
           
        </ContainerList>
   
      </div>
      
      </div>
      );

}