import React from 'react'

import { Container } from './styles'

const SidebarItem = ({ Icon, Text, to }) => {
  return (
    <Container to={to}>
      <Icon  />
      {Text}
    </Container>
  )
}

export default SidebarItem