import React, { useState, useEffect } from "react";
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { FaBars } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import api from "../../Services/api";
import Button from '@mui/material/Button';
import Moment from "moment";

///

import { Container, Titulo, BtnEnviar, ListPessoa, ItemListPessoa,ContainerList } from './styles';

import Sidebar from '../Sidebar';

//  1 | admin | admin@sistema.com | admin123

export const Home = () =>{

  const showSidebar               = () =>{ setSidebar(!sidebar)};
  const navigate                  = useNavigate();
  const [sidebar, setSidebar]     = useState(false);
  const [listemail, setlistemail] = useState(['']);
  const [refresh, setRefresh]     = useState(false);
  const [situacao]                = useState(0);
  const [redirect]                = useState(null);
  const [disabled, setDisabled]   = useState(false);

  const dataCompetencia = new Date().getMonth() + 1 + "/" + new Date().getFullYear();


  // const VerificaLogin = async () => {
  //   let UserLogado = await authService.getLoggedUser();
  //   if(!UserLogado){
  //     console.log('Usuario Logoda: ', UserLogado);
  //     setRedirect({redirectTo: "/Login"})
  //   }
  // }

  // let y = 0;

  useEffect(() => {  
    ValidaUsuario();
    BuscaConfiguracao();
    //criarArquivo();
  }, []);

 /////////////////////// BUSCA PESSOA //////////////////////
  async function ValidaUsuario(){

    api.defaults.headers.post   = {'Content-Type' : 'application/json'};
    api.defaults.headers.common = { 'x-access-token': localStorage.getItem("token") };

      let usuario = await buscaUsuario();
      if(usuario.data[0] != null){
        setlistemail(usuario.data);

      }

  }
  async function buscaUsuario(){
      let response = await api.get('/pessoa/'+ situacao);
      return response;
  }

  /////////////////////// FIM BUSCA PESSOA //////////////////////

  /////////////////////// ENVIA EMAIL //////////////////////

////////////////////////// OLD 14/012023 ///////////////////////////////////

  // enviar email
  // async function SendEmail(e){
  //   setDisabled(true);
  //   let x ;
  //   x = await criarArquivo(); 
    
  //     if(x.length > 0){
  //       ValidaEmail(x);
  //     }else{
  //       alert("Falha ao gerar arquivo")
  //     }
      
  // }

    // async function ValidaEmail(x){

  //     api.defaults.headers.post   = {'Content-Type' : 'application/json'};
  //     api.defaults.headers.post   = {"Content-Type": 'multipart/form-data'};
  //     api.defaults.headers.common = { 'x-access-token': localStorage.getItem("token") };

  //     let email = await EnviaEmail(x);
      
  //     let numTentaivas = 0;

  //     if(email.status === 500 && numTentaivas < 3){
  //       email = await EnviaEmail(x);
  //       numTentaivas = numTentaivas + 1;
  //     }


  //     if(email.status === 200){
  //         await atualizaSituacaoPessoa(email.data.insertId);
  //         alert('Email enviado com sucesso!');
  //         setDisabled(false);
  //         setRefresh(!refresh);
  //         window.location.reload();

          
  //     }else{
  //     alert('Falha ao enviar email')
  //     }

  // }


//   async function criarArquivo(){ 
//     const timeElapsed = Date.now();
//     const today = new Date(timeElapsed);

  
    
//     //listemail.map((item) => (
//     //   newListExcel.push(
//     //   {
//     //     Segurado: item.nome,
//     //     Data_Nascimento: Moment(item.dataNascimento).format('DD/MM/YYYY'),
//     //     cpf: item.cpf,
//     //     mac: "30.000,00",
//     //     iptp: "30.000,00",
//     //     dmha: "3.000,00",
//     //     Inicio: today.toLocaleString('pt-BR').substring(0,10),
//     //     Termino: today.toLocaleString('pt-BR').substring(0,10),
//     //     Qtde_dias: "1",
//     //     Custo_Individual: "R$ 0,88"
      
//     //   })
//     // ))

//     let newListExcel = []
    
//     newListExcel.push([
//       {backgroundColor: "#aabbcc"},
//       {backgroundColor: "#aabbcc"},
//       {backgroundColor: "#aabbcc"},
//       {backgroundColor: "#aabbcc"},
//       {backgroundColor: "#aabbcc"},
//       {backgroundColor: "#aabbcc"},
//       {backgroundColor: "#aabbcc"},
//       {backgroundColor: "#aabbcc"},
//       {
//         value: 'RELAÇÃO DE SEGURADOS VIAGEM',
//         fontWeight: 'bold',
//         backgroundColor: "#aabbcc",
//       },
//       {backgroundColor: "#aabbcc"},
//       {backgroundColor: "#aabbcc"},
//       {backgroundColor: "#aabbcc"},
//       {backgroundColor: "#aabbcc"},
//       {backgroundColor: "#aabbcc"},
//       {backgroundColor: "#aabbcc"},
//       {backgroundColor: "#aabbcc"},
//       {backgroundColor: "#aabbcc"}
//     ],
//     [
//       {backgroundColor: "#aabbcc"},
//       {backgroundColor: "#aabbcc"},
//       {backgroundColor: "#aabbcc"},
//       {backgroundColor: "#aabbcc"},
//       {backgroundColor: "#aabbcc"},
//       {backgroundColor: "#aabbcc"},
//       {backgroundColor: "#aabbcc"},
//       {backgroundColor: "#aabbcc"},
//       {backgroundColor: "#aabbcc"},
//       {backgroundColor: "#aabbcc"},
//       {backgroundColor: "#aabbcc"},
//       {backgroundColor: "#aabbcc"},
//       {backgroundColor: "#aabbcc"},
//       {backgroundColor: "#aabbcc"},
//       {backgroundColor: "#aabbcc"},
//       {backgroundColor: "#aabbcc"},
//       {backgroundColor: "#aabbcc"}
//     ],
//     [
//       {
//         value: 'Nome da empresa',
//         fontWeight: 'bold',
//         backgroundColor: "#aabbcc",
//         span:2
        
//       },
//       null,
//       {backgroundColor: "#aabbcc"},
//       {backgroundColor: "#aabbcc"},
//       {backgroundColor: "#aabbcc"},
//       {backgroundColor: "#aabbcc"},
//       {backgroundColor: "#aabbcc"},
//       {
//         value: 'CNPJ',
//         fontWeight: 'bold',
//         backgroundColor: "#aabbcc"
//       },
//       {backgroundColor: "#aabbcc"},
//       {backgroundColor: "#aabbcc"},
//       {backgroundColor: "#aabbcc"},
//       {backgroundColor: "#aabbcc"},
//       {
//         value: 'Competência a ser fatura',
//         fontWeight: 'bold',
//         backgroundColor: "#aabbcc",
//         span: 3
//       },
//       null,
//       null,
//       {backgroundColor: "#aabbcc"},
//       {backgroundColor: "#aabbcc"}
//     ],
//     [
//       {
//         type: String,
//         value: 'LEANDRO MACIEL SILVA',
//         color: "#FF0000",
//         span: 3
//       },
//       null,
//       null,
//       {backgroundColor: "#aabbcc"},
//       {backgroundColor: "#aabbcc"},
//       {backgroundColor: "#aabbcc"},
//       {backgroundColor: "#aabbcc"},
//       {
//         type: String,
//         value: '36.309.039/0001-32',
//         color: "#FF0000",
//         span: 2
//       },
//       null,
//       {backgroundColor: "#aabbcc"},
//       {backgroundColor: "#aabbcc"},
//       {backgroundColor: "#aabbcc"},
//       {
//         type: String,
//         value: dataCompetencia,
//         color: "#FF0000",
//         align: "center"
//       },
//       {backgroundColor: "#aabbcc"},
//       {backgroundColor: "#aabbcc"},
//       {backgroundColor: "#aabbcc"},
//       {backgroundColor: "#aabbcc"}
//     ],
//     [
//       {backgroundColor: "#aabbcc"},
//       {backgroundColor: "#aabbcc"},
//       {backgroundColor: "#aabbcc"},
//       {backgroundColor: "#aabbcc"},
//       {backgroundColor: "#aabbcc"},
//       {backgroundColor: "#aabbcc"},
//       {backgroundColor: "#aabbcc"},
//       {backgroundColor: "#aabbcc"},
//       {backgroundColor: "#aabbcc"},
//       {backgroundColor: "#aabbcc"},
//       {backgroundColor: "#aabbcc"},
//       {backgroundColor: "#aabbcc"},
//       {backgroundColor: "#aabbcc"},
//       {backgroundColor: "#aabbcc"},
//       {backgroundColor: "#aabbcc"},
//       {backgroundColor: "#aabbcc"},
//       {backgroundColor: "#aabbcc"}
//     ],
//     [
//       {
//         value: 'Apólice',
//         fontWeight: 'bold',
//         backgroundColor: "#aabbcc",
//         align: "center"
//       },
//       {
//         value: 'Estipul Sub',
//         fontWeight: 'bold',
//         backgroundColor: "#aabbcc",
//         align: "center"
//       },    
//       {
//         value: 'Segurado',
//         fontWeight: 'bold',
//         backgroundColor: "#aabbcc",
//         span: 3,
//         align: "center"
//       },
//       null,
//       null,
//       {
//         value: 'Data Nascimento',
//         fontWeight: 'bold',
//         backgroundColor: "#aabbcc",
//         span: 2,
//         align: "center"
//       },
//       null,
//       {
//         value: 'CPF',
//         fontWeight: 'bold',
//         backgroundColor: "#aabbcc",
//         span: 2,
//         align: "center"
//       },
//       null,
//       {
//         value: 'MAC',
//         fontWeight: 'bold',
//         backgroundColor: "#aabbcc",
//         align: "center"
//       },
//       {
//         value: 'IPTP',
//         fontWeight: 'bold',
//         backgroundColor: "#aabbcc",
//         align: "center"
//       },
//       {
//         value: 'DMHA',
//         fontWeight: 'bold',
//         backgroundColor: "#aabbcc",
//         align: "center"
//       },{
//         value: 'Início',
//         fontWeight: 'bold',
//         backgroundColor: "#aabbcc",
//         align: "center"
//       },
//       {
//         value: 'Término',
//         fontWeight: 'bold',
//         backgroundColor: "#aabbcc",
//         align: "center"
//       },
//       {
//         value: 'Qtde dias',
//         fontWeight: 'bold',
//         backgroundColor: "#aabbcc",
//         align: "center"
//       },
//       {
//         value: 'Custo Individual',
//         fontWeight: 'bold',
//         backgroundColor: "#aabbcc",
//         align: "center"
//       },
//       {backgroundColor: "#aabbcc"}
//     ]);

//     listemail.map((item) => (

//       newListExcel.push([
//         {},
//         {},
//         {
//           value: item.nome,
//           span: 3
//         },
//         null,
//         null,
//         {
//           value: Moment(item.dataNascimento).format('DD/MM/YYYY'),
//           span: 2,
//           align: "center"
//         },
//         null,
//         {
//           value: item.cpf,
//           span: 2,
//           align: "center"
//         },
//         null,
//         {
//           value: '30.000,00',
//           align: "center"
//         },
//         {
//           value: '30.000,00',
//           align: "center"
//         },
//         {
//           value: '3.000,00',
//           align: "center"
//         },
//         {
//           value: today.toLocaleString('pt-BR').substring(0,10),
//           align: "center"
//         },
//         {
//           value: today.toLocaleString('pt-BR').substring(0,10),
//           align: "center"
//         },
//         {
//           value: 1,
//           align: "center"
//         },
//         {
//           value:' R$ 0,88',
//           align: "center"
//         },
//         {}
//       ])
      
//       ))

//     return newListExcel

// }


////////////////////////// OLD 14/012023 ///////////////////////////////////



  async function SendEmail(e){
    setDisabled(true);

      ValidaEmail();
   
  }

  async function ValidaEmail(){


    api.defaults.headers.post   = {'Content-Type' : 'application/json'};
    api.defaults.headers.post   = {"Content-Type": 'multipart/form-data'};
    api.defaults.headers.common = { 'x-access-token': localStorage.getItem("token") };

    let email = await EnviaEmail();
    
    let numTentaivas = 0;

    if(email.status === 500 && numTentaivas < 3){
      email = await EnviaEmail();
      numTentaivas = numTentaivas + 1;
    }

    console.log('Atualizando cliente!');



    if(email.status === 200){
        await atualizaSituacaoPessoa(email.data.insertId);
        alert('Email enviado com sucesso!');
        setDisabled(false);
        setRefresh(!refresh);
        window.location.reload();

        
    }else{
    alert('Falha ao enviar email')
    }

}

  async function EnviaEmail(){

      let response = await api.post(
          '/send', {
            'situacao': 0
        }

          );
          console.log('Response sendmail: ', response);
        return response;
  }
  async function atualizaSituacaoPessoa(idEmail){

    let id = listemail.map((id) => id.id + '' );

    // console.log("Aqui e o id email que foi passado: ", idEmail);

    // api.defaults.headers.post   = {'Content-Type' : 'application/json'};
    // api.defaults.headers.common = { 'x-access-token': localStorage.getItem("token") };

    let response = await api.put('/pessoa/update/id/',{
      'idPessoa': id,
      'idEmail': idEmail
    });

    // console.log('Response do update Pessoa:', response);
    
  }

  /////////////////////// FIM ENVIA EMAIL //////////////////////

  ////////////////////// Deletar Cadastro /////////////////////

  async function DeletePessoa(id){
    let delpessoa = await dellPessoa(id);

    if(delpessoa.status === 200){

        alert('Cadastro removido com sucesso!');
        window.location.reload(false);
        setRefresh(!refresh);
        window.location.reload();

    }else{
      alert('Falha ao enviar email')
    }

  }

  async function dellPessoa(id){
    let response = await api.delete(
        '/pessoa/delete/'+id

        );
      return response;
  }

  ////////////////////// Fim Deletar Cadastro /////////////////////


  /////////////////////  Buscando informações de e-mail ///////////

  async function BuscaConfiguracao(){

    let CONFIGURACAO = await buscaConfig();

    if(CONFIGURACAO.status === 200){
        if(CONFIGURACAO.data[0].id >= 0){
          localStorage.setItem("idConfiguracao",CONFIGURACAO.data[0].id);
          localStorage.setItem("host",CONFIGURACAO.data[0].host);
          localStorage.setItem("user",CONFIGURACAO.data[0].user);
          localStorage.setItem("port",CONFIGURACAO.data[0].port);
          localStorage.setItem("secure",CONFIGURACAO.data[0].secure);  
          localStorage.setItem("pass",CONFIGURACAO.data[0].pass);
          localStorage.setItem("remetente",CONFIGURACAO.data[0].remetente);
          localStorage.setItem("destinatario",CONFIGURACAO.data[0].destinatario);
          localStorage.setItem("assunto",CONFIGURACAO.data[0].assunto);
          localStorage.setItem("nomeempresa",CONFIGURACAO.data[0].nomeEmpresa);
          localStorage.setItem("copia",CONFIGURACAO.data[0].copia);
        }
    }else{
    alert('Falha ao Buscar Configuracoes. tente novamente');

    }
  }
  async function buscaConfig(){
      let response = await api.get('/configuracao/');

      return response;

  }

  /////////////////////  Fim Buscando informações de e-mail ///////////
  const editar = (value) =>{
    navigate('/AlterarCadastro', { state : value });
  }


  const deleteCadastro = (value) =>{
    DeletePessoa(value.id);
  }


  // criarArquivo();
  //   if(redirect){
  //     return(
  //       <redirect to={redirect}/>
  //     )
  //   }

    return(
      <div>
        <div>
          <Container>
            <FaBars onClick={showSidebar} />
            {sidebar && <Sidebar active={setSidebar} />}
          </Container>
        </div>
        <div>
            <ContainerList>
                {listemail[0]?
                      <>
                      {listemail.map((value) => {
                        const labelId = `checkbox-list-label-${value}`;

                        return (
                              <ListItem
                                key={value.id+value.cpf}
                                secondaryAction={
                                  <>
                                    <IconButton edge="end" aria-label="editar" className="btHome" onClick={() => editar(value)} >
                                      <EditIcon/>
                                    </IconButton>

                                    <IconButton edge="end" aria-label="editar" className="btHome" onClick={() => deleteCadastro(value)}>
                                      <DeleteIcon />
                                    </IconButton>
                                  </>
                                }
                                disablePadding
                              >
                                <ListPessoa>
                                <ItemListPessoa>
                                  
                                  <ListItemButton onClick={() => editar(value)} role={undefined}  dense>
                                    <ListItemText id={labelId} 
                                      
                                    primary={
                                        `
                                        Nome:
                                        Email:
                                        Telefone:  
                                        Documento:
                                        `}
                                        secondary={`
                                        ${value.nome} - 
                                        ${value.email} - 
                                        ${value.telefone} - 
                                        ${value.cpf}
                                        
                                      `}
                                        />

                                  </ListItemButton>
                                  </ItemListPessoa>
                                </ListPessoa>
                                
                              </ListItem>
                              
                        );
                      })}
                  </>

                :
                  
                <Titulo>
                  Nenhum Cadastro novo encontrado !
                </Titulo>

                }
            
          </ContainerList>
              {listemail[0] ?
                <BtnEnviar>
                  <Button variant="contained" disabled={disabled} disableElevation onClick={ () => {SendEmail()}}>
                    ENVIAR
                  </Button>
                </BtnEnviar>
              :
                <></>
              }
    
        </div>
      
      </div>
      );         
}