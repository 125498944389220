import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useNavigate } from 'react-router-dom';
import api from "../../Services/api";
import "./login.css";


//  1 | admin | admin@sistema.com | admin123


export const Login = () =>{

    const [token, setToken] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();

    let vToken = "";

    async function ValidaUsuario(){
        api.defaults.headers.post   = {'Content-Type' : 'application/json'};
        let response = await api.post(
        '/get-token', 
        {
            'user': 'xxtgs627683yshjsb872gd_nix*',
            'pwd': 'kkbecuix_jwedbxiw62734782bxib9823gsdgxxx_',
        }
        );


        vToken = response.data.token;

        localStorage.setItem("token",vToken);

        setToken(vToken); 

        let usuario = await buscaUsuario();

        if(usuario.data.resultado[0] != null){

          localStorage.setItem("Usuario",email);
          localStorage.setItem("Senha",password);

          navigate("/Home", { state: vToken });

        }else{
          alert('Usuario Invalido!')
        }

    }

    async function buscaUsuario(){   
        
          api.defaults.headers.common = { 'x-access-token': localStorage.getItem("token") };
          let response = await api.get('/getUsuarioLogin/'+email+'/'+password);
          return response;
      }
    
      function validateForm() {
        return email.length > 0 && password.length > 0;
      }
    
      function handleSubmit(event) {
        event.preventDefault();
      } 
      
      return(
        <div>
          <div>
            <div align="center" className="row">
                <div className="col-sm-8">
                  <div >
                  </div>
                </div>

                <div align="center" className="col-sm-2" style={{paddingTop: '3%'}}>
                  <br/><br/>

                  <div align="center" className="Login">
                    

                    <Form onSubmit={handleSubmit}>
                      <br/>
                      <Form.Group className="groups" size="lg" controlId="email">
                        <div className="tituloLogin">
                          <h1>Login</h1>
                        </div>
                       
                        
                        <Form.Control
                          autoFocus
                          type="text"
                          value={email}
                          className="campo"
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder="Login"
                        />
                      </Form.Group>
                      <br/>
                      <Form.Group className="groups" size="lg" controlId="password">
                        <Form.Control
                          type="password"
                          value={password}
                          className="campo"
                          onChange={(e) => setPassword(e.target.value)}
                          placeholder="Senha"
                        />
                      </Form.Group>
                      <br/>
                      <Button  size="lg" className="btn_login" type="submit" onClick={() => ValidaUsuario()}>
                        Entrar
                      </Button>
                      <br/>
                    </Form>

                  </div>
                </div>
              
            </div>
          </div>
        </div>
        );

}